<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">体系审核软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        通过软件平台，自动随机出题，支持移动端，即使在非办公现场审核也方便作记录。
      </p>
      <br />
      <br />
      <p class="PTitle LeftBor">
        解决客户的什么问题：
      </p>
      <p class="SmallP">
        人员流动大，导致审核员年年新
        <br />
        新审核员缺少经验和审核技巧
        <br />
        每次审核前花费时间编制检查表
        <br />
        审核员的知识经验难以传承
      </p>

      <br />
      <p class="PTitle LeftBor">
        我们公司的解决方案：
      </p>
      <p class="SmallP">
        帮助你构建题库
        <br />
        导入题库
        <br />
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>